@import url(https://fonts.googleapis.com/css?family=Lato:400,700,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@700&family=Varela&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@700,800,900&display=swap);
* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

body {
  font-family: -apple-system, system-ui, sans-serif;
  font-size: 1.125em;
  font-weight: 400;
  line-height: 150%;
  color: rgba(0, 0, 0, 0.75);
}

#loader { 
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.primary {
  /* color: "#da0d07"; */
  fill: "#da0d07";
}

h1 {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 900;
  color: #272730;
  margin: 0;
  margin-bottom: 0.5rem;
  font-size: 86px;
  line-height: 1em;
  letter-spacing: -4px;
}

h2 {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 900;
  color: #272730;
  font-size: 62px;
  line-height: 62px;
  letter-spacing: -2px;
  margin-top: 0;
  margin-bottom: 3rem;
}

h3 {
  font-style: normal;
  font-weight: 600;
  color: #272730;
  font-size: 20px;
  line-height: 20px;
  margin-top: 2rem;
  margin-bottom: 0;
}

p {
  font-size: 1.125em;
  font-weight: 400;
  line-height: 150%;
  color: rgba(0, 0, 0, 0.5);
  margin-top: 1rem;
  margin-bottom: 0;
  -webkit-font-smoothing: antialiased;
}

.canvas > div {
  z-index: 1;
}

.loading {
  padding: 10px;
  -webkit-transform: translate3d(-50%, -50%, 0);
          transform: translate3d(-50%, -50%, 0);
}

.scrollArea {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
}

